<!--
 * @Author: your name
 * @Date: 2021-01-28 19:42:51
 * @LastEditTime: 2021-01-29 11:35:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/views/newIndex/components/FromWorld.vue
-->
<template>
  <div class="wap_rel_con">
    <img :src="pic" alt="" />
  </div>
</template>
<script>
export default {
  props: {
    pic: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped lang="less">
.wap_rel_con {
  padding: calc(100vw * 2.4 / 37.5) calc(100vw * 2.2 / 37.5) 0;
  img {
    display: block;
    width: 100%;
  }
}
</style>
